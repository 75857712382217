import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Industry from "../components/Industry"
import Title from "../components/Title"

const Industries = ({
  data: {
    allStrapiIndustries: { nodes: industries },
  },
}) => {
  return (
    <Layout>
      <section className={"blog-page"}>
        <section className={"section jobs"}>
          <Title title={"Industries we serve"} />
          <div className="jobs-list">
            {
              industries.map((industry) => {
                return (
                  <Industry industry={industry} isLink/>
                )
              })
            }
          </div>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiIndustries {
      nodes {
        description
        id
        shortDescription {
          name
          id
        }
        slug
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Industries
