import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const Industry = ({ industry, isLink }) => {

  const { slug, title, shortDescription } = industry

  return (
    <article className="job-info">
      <h3>{title}</h3>
      {shortDescription.map(item => (
        <div key={item.id} className={"job-desc"}>
          <FaAngleDoubleRight className={"job-icon"}></FaAngleDoubleRight>
          <p>{item.name}</p>
        </div>
      ))}
      {
        isLink && (
          <Link to={`/industries/${slug}`} className={"btn center-btn"}>
            more details
          </Link>
        )
      }
    </article>
  )
}

export default Industry
